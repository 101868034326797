import API from "@/api";

export default {
  add: (data) => {
    return API.requestPost({
      data,
      url: "really/add",
      auth: true,
    },true);
  },

  query: (data) => {
    return API.requestPost({
      data:data,
      url: "really/query",
      auth: true,
    },true);
  },
  queryMy: (data) => {
    return API.requestPost({
      data:data,
      url: "really/queryMy",
      auth: true,
    },true);
  },
};
