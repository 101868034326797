<template>
  <div class="continer">
    <div class="header">
      <h1 class="title">真假财务</h1>
    </div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <!--查询栏-->
        <el-row :gutter="10">
          <el-col :span="3">
            <el-input v-model="name" size="medium" placeholder="请输入姓名"></el-input>
          </el-col>
          <el-col :span="1">
            <el-button type="primary" size="medium" icon="el-icon-search" @click="search">查询</el-button>
          </el-col>
        </el-row>
      </div>
      <div v-if="add">
        <el-row :gutter="12">
          <el-col :span="12">
            <el-card shadow="hover">
              <div slot="header">
                <el-row>
                  <el-col :span="22">
                    <h1 class="title">姓名 {{ name }}</h1>
                  </el-col>
                  <el-col :span="2">
                    <h1>评价</h1>
                  </el-col>
                </el-row>
              </div>
              <!--单选投票类型-->
              <el-row class="radio-type" v-for="it in data.typeList" :key="it.key">
                <el-col :span="12">
                  <el-radio v-model="type" :label="it.key" @input="change">{{ it.value }}</el-radio>
                </el-col>
                <el-col :span="10">
                  <el-progress
                    :percentage="it.count == 0 ? 0 : total == 0 ? 100 : Math.round(it.count * 100 / total)"></el-progress>
                </el-col>
              </el-row>
              <el-button style="margin-top: 20px;" type="primary" size="medium" @click="saveEvaluate">确认投票</el-button>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </el-card>
  </div>
</template>
<script>
import service from './api'
export default {
  data () {
    return {
      name: null,
      add: false,
      data: {
        typeList: [{ key: 0, value: '真财务', count: 0 }, { key: 1, value: '假财务', count: 0 },
        { key: 2, value: '操作手', count: 0 }],
      },
      total: 0,
      type: null,
      selectedType: null,
    }
  },
  methods: {
    // 查询对象
    search () {
      if(!this.name){
        this.$message.error('姓名不能为空');
        return
      }
      this.total = 0
      this.type = null
      this.selectedType = null
      this.data.typeList = [{ key: 0, value: '真财务', count: 0 }, { key: 1, value: '假财务', count: 0 },{ key: 2, value: '操作手', count: 0 }],
      service.query({name:this.name,flag:1}).then((res) => {

        if(res.flag){
          this.total = res.total
          this.data.typeList.forEach((element) =>{
              var value = res.map[element.key]
              if(value){
                element.count = value
              }
          });
          this.add = true
        }else{
          this.$confirm('没有查询到此人，是否添加此人', '提示', {
            confirmButtonText: '添加',
            showCancelButton: false,
            showClose: false,
            type: 'warning',
            center: true
          }).then(() => {
            this.add = true
          }).catch(() => {
          })
        }
      })
      
      
    },
    // 评价对象
    evaluate () {
      this.dialogVisible2 = true
      service.queryMy({}).then((res) => {
          this.tableData = res
      })
    },
    vote(){
      this.dialogVisible = true
      this.form = {}
    },
    saveEvaluate () {
      if(!this.name){
        this.$message.error('姓名不能为空');
        return
      }
      var param = {
        name:this.name,
        type:this.selectedType,
        typeName:this.selectedTypeName,
        flag:1
      }
      service.add(param).then((res) => {
        this.search ()
      })
    },
    change (val) {
      console.log('change',val)
      if (this.selectedType != null) {
        this.data.typeList[this.selectedType].count--
      }
      this.selectedType = val
      this.data.typeList[val].count++
      this.total = this.data.typeList.reduce((total, obj) => {
        return total + obj.count;
      }, 0)
    },
    // 上传凭证开始
    handleChange (file, fileList) {
      console.log('handleChange',file,fileList)
      this.fileList = fileList.slice(-3);
    },
    handleRemove (file, fileList) {
      console.log('handleChange',file,fileList)
      this.fileList = fileList.slice(-3);
    },
    // 上传凭证结束
    submit () {
      this.data.completed = true
      this.$confirm('确认提交投票?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
      }).catch(() => {
      })
    }
  }
}

</script>
<style lang="scss">
.header {
  margin-bottom: 20px;
}

.continer {
  width: 100%;
  height: 100%;
}

.title {
  font-weight: bold;
  color: black;
}

.box-card {
  width: 100%;
  height: 100%;
}

.oprate {
  margin: 0 0 20px 0px;
}

.radio-type {
  margin: 15px 15px 15px 0px;
}

.el-card__body {
  padding: 20px;
  //height: 393px;
}

.tag-row {
  margin: 5px;
}
</style>